import customError from "../util/customError"
import * as Sentry from "@sentry/react"
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js"

/* ----------- EXCEPTIONS ---------- */
export const UnableToConnectException = customError(
  "UnableToConnectException",
  "Unable to connect to the server."
)
export const ServerErrorException = customError(
  "ServerErrorException",
  "There was an error on the server."
)
export const NotFoundException = customError(
  "NotFoundException",
  "Endpoint not found."
)
export const AuthenticationException = customError(
  "AuthenticationException",
  "User is not authenticated."
)
export const BadRequestException = customError(
  "BadRequestException",
  "Bad request sent."
)
export const TimeoutException = customError(
  "TimeoutException",
  "The request timed out."
)

/* ----------- PRIVATE FUNCTIONS ---------- */
const ADDRESS = process.env.GATSBY_V2_API_URL

function convertError(data) {
  if (data.errors && data.errors[0]) {
    if (Array.isArray(data.errors)) {
      return data.errors[0].reason || data.errors[0]
    } else if (typeof data.errors === "object") {
      const firstKey = Object.keys(data.errors)[0]
      return data.errors[firstKey]
    }
  }

  return "An error occurred"
}

function getAuthHeader() {
  const existingTokensStr = localStorage.getItem("theBunch-tenantTokens")

  if (existingTokensStr) {
    const existingTokens = JSON.parse(existingTokensStr)
    return "Bearer " + existingTokens.accessToken
  }

  const existingMeterReadingOnlyTokensStr = localStorage.getItem(
    "theBunch-meterReadingOnlyTokens"
  )

  if (existingMeterReadingOnlyTokensStr) {
    const existingMeterReadingOnlyTokens = JSON.parse(
      existingMeterReadingOnlyTokensStr
    )
    return "Bearer " + existingMeterReadingOnlyTokens.accessToken
  }

  const existingUpdatePaymentDetailsOnlyTokensStr = localStorage.getItem(
    "theBunch-updatePaymentDetailsOnlyTokens"
  )

  if (existingUpdatePaymentDetailsOnlyTokensStr) {
    const existingUpdatePaymentDetailsOnlyTokens = JSON.parse(
      existingUpdatePaymentDetailsOnlyTokensStr
    )
    return "Bearer " + existingUpdatePaymentDetailsOnlyTokens.accessToken
  }
}

async function request(path, settings) {
  let response
  try {
    response = await fetch(`${ADDRESS}${path}`, settings)
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
    throw new UnableToConnectException()
  }

  if (response.status === 404) throw new NotFoundException()
  if (response.status === 500) throw new ServerErrorException()
  if (response.status === 401) throw new AuthenticationException()
  if (response.status === 403) throw new AuthenticationException()
  if (response.status === 400)
    throw new BadRequestException(convertError(await response.json()))

  return response
}

export async function get(path, newAccessToken) {
  return await request(path, {
    headers: {
      Authorization: newAccessToken ? `Bearer ${newAccessToken}` : getAuthHeader(),
    },
  })
}

export async function del(path, body) {
  return await request(path, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  })
}

export async function post(path, body) {
  return await request(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  })
}

export async function put(path, body) {
  return await request(path, {
    method: "PUT",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: getAuthHeader(),
    },
    body: JSON.stringify(body),
  })
}

export async function postFiles(path, files, body) {
  const formData = new FormData()

  files.forEach((file, index) => {
    formData.append(`file${index + 1}`, file)
  })

  Object.keys(body)
    .filter(key => body[key])
    .forEach(key => {
      if (Array.isArray(body[key])) {
        // If the value is an array, append each item
        body[key].forEach((item, idx) => {
          formData.append(`${key}[]`, item)
        })
      } else {
        // If it's not an array, append the value directly
        formData.append(key, body[key])
      }
    })

  return await request(path, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: getAuthHeader(),
    },
    body: formData,
  })
}

/* ----------- PUBLIC FUNCTIONS ----------- */
export async function refreshToken(refreshToken) {
  const response = await post("/auth/refresh", {
    RefreshToken: refreshToken,
  })
  return await response.json()
}

export async function logError(payload) {
  const response = await post("/systemLogging", payload)
  return await response.json()
}

export async function getFullUrl(token) {
  const response = await get(
    `/urlShortener/full-url?shortenedUrlToken=${token}`
  )
  return await response.json()
}

export async function adminLogin(payload) {
  const response = await post("/tenantPortal/admin/login-as-user", payload)
  return await response.json()
}

export async function login({ email, password }) {
  console.log("Login", { email, password })
  const response = await post("/tenantPortal/login", {
    email: email.toLowerCase(),
    password,
  })
  return await response.json()
}

export async function checkAccountStatus({ email }) {
  const response = await post("/tenantPortal/check-account-status", {
    email: email.toLowerCase(),
  })
  return await response.json()
}

export async function getMe() {
  const response = await get("/tenantPortal/me")
  return await response.json()
}

export async function getContact(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/contact`)
  return await response.json()
}

export async function updateUserContact(tenancyId, payload) {
  const response = await put(
    `/tenantPortal/me/tenancies/${tenancyId}/contact`,
    payload
  )
  return await response.json()
}

export async function updateUserTenancy(tenancyId, payload) {
  let files = []
  files.push(payload.tenancyAgreement)

  const response = await postFiles(`/tenantPortal/me/tenancies/${tenancyId}`, files, payload)
  return await response.json()
}

export async function updateAdditionalServices(tenancyId, payload) {
  const response = await put(`/tenantPortal/me/tenancies/${tenancyId}/additional-services`, payload)
  return await response.json()
}

export async function updateProfile(payload) {
  const response = await put("/tenantPortal/me", payload)
  return await response.json()
}

export async function updatePassword(payload) {
  const response = await put("/tenantPortal/me/password", payload)
  return await response.json()
}

export async function getMyTenancies() {
  const response = await get("/tenantPortal/me/tenancies")
  return await response.json()
}

export async function getMyTenancy(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}`)
  return await response.json()
}

export async function getTenancyOverview(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/overview`)
  return await response.json()
}

export async function getTenancyUsage(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/usage`)
  return await response.json()
}

export async function getTenancyTenants(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/tenants`)
  return await response.json()
}

export async function getAgencies() {
  const response = await get("/tenantPortal/agencies")
  return await response.json()
}

export async function sendSignUpReminder(tenantId) {
  const response = await post(
    `/tenantPortal/tenants/${tenantId}/send-sign-up-reminder`
  )
  return await response.json()
}

export async function getTenancyContractServices(tenancyId) {
  const response = await get(
    `/tenantPortal/me/tenancies/${tenancyId}/contract-services`
  )
  return await response.json()
}

export async function getTenancyMeterReading(meterReadingId) {
  const response = await get(
    `/tenantPortal/me/meter-readings/${meterReadingId}`
  )
  return await response.json()
}

export async function getTenancyBillsAndPayments(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/bills`)
  return await response.json()
}

export async function getInvoices(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/invoices`)
  return await response.json()
}

export async function getInvoicePdf(invoiceId) {
  const response = await get(`/tenantPortal/me/invoices/${invoiceId}/pdf`)
  return await response.json()
}

export async function getTenancyMeters(tenancyId) {
  const response = await get(`/tenantPortal/me/tenancies/${tenancyId}/meters`)
  return await response.json()
}

export async function getTenancyMeter(tenancyId, service) {
  const response = await get(
    `/tenantPortal/me/tenancies/${tenancyId}/meters/${service}`
  )
  return await response.json()
}

export async function uploadTenancyMeterReading(tenancyId, meterId, payload) {
  let files = []

  if (payload.elecPhoto) {
    files.push(payload.elecPhoto)
  }

  if (payload.elecPhoto2) {
    files.push(payload.elecPhoto2)
  }

  if (payload.gasPhoto) {
    files.push(payload.gasPhoto)
  }

  const response = await postFiles(
    `/tenantPortal/me/tenancies/${tenancyId}/meter-readings/${meterId}`,
    files,
    {
      reading: payload.reading,
      reading2: payload.reading2,
      type: payload.type,
    }
  )
  return await response.json()
}

export async function acceptInvite({
  code,
  password,
  firstName,
  lastName,
  signUpToNewsletter,
}) {
  const response = await post("/tenantPortal/accept-invite", {
    AccountSetupToken: code,
    Password: password,
    FirstName: firstName,
    LastName: lastName,
    SignUpToNewsletter: signUpToNewsletter,
  })
  return await response.json()
}

export async function acceptInvitePhoenix({
  password,
  tenantId,
  emailAddress,
}) {
  const response = await post("/tenantPortal/accept-invite-phoenix", {
    TenantId: tenantId,
    Password: password,
    EmailAddress: emailAddress,
  })
  return await response.json()
}

export async function requestPasswordReset({ email }) {
  const response = await post("/tenantPortal/password/request-reset", {
    email: email.toLowerCase(),
  })
  return await response.json()
}

export async function resetPassword({ code, password }) {
  const response = await post(`/tenantPortal/password/reset/${code}`, {
    Password: password,
  })
  return await response.json()
}

export async function verifyAccount({ code }) {
  const response = await post(`/tenantPortal/verify-account/${code}`)
  return await response.json()
}

export async function resendVerificationEmail() {
  const response = await post("/tenantPortal/resend-verification-email")
  return await response.json()
}

export async function storeTenantPlans({ email, plans, tenancyEndDate }) {
  const response = await post("/tenant/plans", {
    email: email.toLowerCase().trim(),
    plans,
    tenancyEndDate,
  })
  return await response.json()
}

export async function storeLeadTenantPlans({ email, plans, tenancyEndDate }) {
  const response = await post("/tenant/lead-plans", {
    email: email.toLowerCase().trim(),
    plans,
    tenancyEndDate,
  })
  return await response.json()
}

export async function storeCallbackRequest({ contactId, request }) {
  const response = await post(
    `/tenant/household-callback-request/${contactId}/${request}`
  )
  return await response.json()
}

export async function getResignDetails(resignAuthToken) {
  const response = await get(`/tenant/resign/${resignAuthToken}`)
  return await response.json()
}

export async function agreeToResignTerms(resignAuthToken) {
  const response = await post(`/tenant/resign/${resignAuthToken}/agree`)
  return await response.json()
}

export async function inviteNewTenant(payload) {
  const response = await post("/tenant/invite-new-tenant", payload)
  return await response.json()
}

export async function getNewTenantDetails(newTenantAuthString) {
  const response = await get(
    `/tenant/new-tenant-details/${newTenantAuthString}`
  )
  return await response.json()
}

export async function updateNewTenantDetails(newTenantAuthString, payload) {
  const response = await put(
    `/tenant/new-tenant-details/${newTenantAuthString}`,
    payload
  )
  return await response.json()
}

export function useSetupStripePayment() {
  const stripe = useStripe()
  const elements = useElements()

  return async ({ clientSecret, name, address }) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let result = null;

    if (clientSecret.startsWith("seti")) {
      result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            address: {
              line1: address.addressLine1,
              line2: address.addressLine1,
              postal_code: address.postcode,
              city: address.city,
              state: address.county, // county
              //country: address.country
            }
          },
        },
      })
    } else {
      result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name,
            address: {
              line1: address.addressLine1,
              line2: address.addressLine1,
              postal_code: address.postcode,
              city: address.city,
              state: address.county, // county
              //country: address.country
            }
          },
        },
      })
    }

    if (result.error) {
      return { isSuccess: false, error: result.error.message }
    } else {
      // The payment has been processed!
      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        return { isSuccess: true }
      }
      else if (result.setupIntent && result.setupIntent.status === "succeeded") {
        return { isSuccess: true }
      }
      else if (result.setupIntent.status === "succeeded") {
        return { isSuccess: true }
      } else {
        return { isSuccess: false, error: result.message }
      }
    }
  }
}

export async function newTenantSigned(newTenantAuthString) {
  const response = await post(
    `/tenant/new-tenant-details/${newTenantAuthString}/signed-up`
  )
  return await response.json()
}

export async function checkBroadbandAvailability(payload) {
  const response = await post("/quote/check-broadband-availability", payload)
  return await response.json()
}

export async function getHouseholdAccountClosureStatements(
  authenticationString
) {
  const response = await get(
    `/household/account-closure-statements/${authenticationString}`
  )
  return await response.json()
}

export async function getContactAccountClosureStatement(authenticationString) {
  const response = await get(
    `/contact/account-closure-statement/${authenticationString}`
  )
  return await response.json()
}

export async function lookupAddresses(postCode) {
  const response = await post("/quote/lookup-address", {
    postCode,
  })
  return await response.json()
}

export async function getBillingPortal(tenancyId, newAccessToken) {
  const response = await get(
    `/tenantPortal/me/tenancies/${tenancyId}/billing-portal`,
    newAccessToken
  )
  return await response.json()
}

// Meter reading request
export async function loginWithMeterReadingRequestToken(payload) {
  const response = await post(
    "/tenantPortal/login-with-meter-reading-request-token",
    payload
  )
  return await response.json()
}

export async function getTenancyFromMeterReadingRequestToken(
  meterReadingRequestToken
) {
  const response = await get(
    `/tenantPortal/tenancy-from-meter-reading-request-token/${meterReadingRequestToken}`
  )
  return await response.json()
}

// Payment and bank details
export async function loginWithPaymentDetailsToken(payload) {
  const response = await post(
    "/tenantPortal/login-with-update-payment-details-token",
    payload
  )
  return await response.json()
}

export async function getBankDetails(tenancyId) {
  const response = await get(
    `/tenantPortal/me/tenancies/${tenancyId}/bank-details`
  )
  return await response.json()
}

export async function updateBankDetails(tenancyId, payload) {
  const response = await put(
    `/tenantPortal/me/tenancies/${tenancyId}/bank-details`,
    payload
  )
  return await response.json()
}
